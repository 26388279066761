import React from 'react';
import Layout from "../../components/page-objects/Layout";
import Header from "../../components/page-objects/Header";
import BlogRoll from '../../components/page-objects/BlogRoll';
import Headerimage from '../../img/heading-blogindex.svg';
import Spacer from '../../components/ui-objects/Spacer';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Header
          // backgroundPositie="-145"
          headerImage={Headerimage}
          promoblockText={""}
          showPromoBlock={false}
          headerHeight={400}
        ></Header>
        <div className={"blog-index"}>
          <BlogRoll />
        </div>
        <Spacer size="l" />
      </Layout>
    );
  }
}
