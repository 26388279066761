import React from 'react'
import PropTypes from 'prop-types'
import Card from "../ui-objects/Card";
import { graphql, StaticQuery } from 'gatsby'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <section className="blogroll">
        {posts &&
          posts.map(({ node: post }) => (
              <Card
                width={'33%'}
                cardHeader={post.frontmatter.title}
                cardContent={post.excerpt}
                showButton={true}
                buttonType={"text"}
                buttonLabel={"Lees meer..."}
                url={post.fields.slug}
                key={post.id}
              />
          ))}
      </section>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

const queryBlogRoll = () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 125)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  extension
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);

export default queryBlogRoll